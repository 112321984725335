.select-loader {
    display: flex;
    top: 50%;
    max-height: 230px;
    justify-content: center;
    align-items: center;
    width: -webkit-fill-available;
    position: absolute;
    overflow: hidden;
    background-color: white;
    margin: -15px 0px 0px -15px;
    pointer-events: none;
}

.switch-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: -webkit-fill-available;
    height: 100px;
    font-size: 12px;
    overflow: hidden;
}
