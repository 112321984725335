.webhook-input {
    margin-bottom: 20px;
}

.webhook-input-label {
    margin-bottom: 5px;
}

.webhook-input-label :global(.wz-infotext__subtitle) {
    padding-top: 0 !important;
}

.webhook-section-title {
    font-weight: 500;
    margin: 20px 0 10px 0;
}

.webhook-type {
    display: flex;
    align-items: center;
}

.webhook-type img {
    height: 25px;
    margin-right: 10px;
}

.webhook-informations,
.webhook-subdetails {
    position: relative;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
}

.webhook-informations-content {
    display: flex;
    width: 100%;
    /* justify-content: unset !important; */
}

.webhook-details {
    display: flex;
    margin-top: 10px;
}

.webhook-subdetails {
    justify-content: flex-start;
}

.webhook-edit {
    position: absolute;
    right: 0px;
    cursor: pointer;
    white-space: nowrap;
    font-weight: 500;
}

.webhook-calls-pending {
    color: #9933FF;
}

.webhook-calls-success {
    color: #39c433;
}

.webhook-calls-fail {
    color: #f0480a;
}

.webhook-call-payload {
    overflow: hidden;
    overflow-y: auto;
    background-color: rgb(var(--lighter-color));
    border: solid 1px #e2dbdb;
    border-radius: 6px;
    padding: 10px;
    color: #878c9b;
}

.webhook-logs-block {
    display: flex;
}
.webhook-logs {
    display: flex;
    margin-right: 5px;
}

.webhook-logs-trigger {
    display: flex;
    justify-content: space-between;
}

.webhook-logs-trigger :global(.wz-framebutton) {
    position: absolute;
    right: 10px;
    top: 10px;
    font-weight: 500;
}

.webhook-logs-trigger :global(.wz-framebutton__icon) {
    font-size: 1.2em !important;
}

.webhook-tooltip {
    right: 0;
    top: 0;
}

.webhook-filters-container{
    display: flex;
    justify-content: space-between;
}

.webhook-executed-calls-container :global(.wz-accordion__text){
    width: 100%;
    margin-right: 15px;
}

.webhook-executed-call-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.webhook-executed-call-section-title{
    text-transform: uppercase;
}
